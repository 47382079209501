import React, { useState } from "react";

const initialState = {
  name: "",
  mobileNo: "",
  message: "",
};

export const Contact = () => {
  const [{ name, mobileNo, message }, setState] = useState(initialState);
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => {
    setState({ ...initialState });
    setSuccessMessage("Message sent successfully!");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const whatsappMessage = `Name: ${name}%0AMobile No: ${mobileNo}%0AMessage: ${message}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=7276691121&text=${whatsappMessage}`;
    
    window.open(whatsappUrl, "_blank");
    
    clearState();
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us a message on WhatsApp, and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                        value={name}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="mobileNo"
                        name="mobileNo"
                        className="form-control"
                        placeholder="Mobile Number"
                        required
                        onChange={handleChange}
                        value={mobileNo}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                    value={message}
                  ></textarea>
                </div>
                <div id="success">{successMessage}</div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                Gokul Nagar, Bhiwandi-421302
              </p>
            </div>
            <div className="contact-item">
  <p>
    <span>
      <i className="fa fa-phone"></i> Phone
    </span>{" "}
    <a href="tel:+917276691121" style={{ color: "inherit", textDecoration: "none" }}>
      +91-7276691121
    </a>{" "}
    /{" "}
    <a href="tel:+917499391121" style={{ color: "inherit", textDecoration: "none" }}>
      7499391121
    </a>
  </p>
</div>

            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                sales@riddhi-infotech.com
              </p>
            </div>
            <div className="contact-item">
  <a
    href="https://api.whatsapp.com/send?phone=7276691121"
    target="_blank"
    rel="noopener noreferrer"
    className="whatsapp-link"
    style={{
      color: "#ffffff", // White color for the text
      fontSize: "18px",
      textDecoration: "none",
      display: "inline-flex",
      alignItems: "center",
    }}
  >
    <i
      className="fa fa-whatsapp"
      style={{
        marginRight: "5px",
        color: "#25D366", // WhatsApp green color for the icon
        fontSize: "24px", // Slightly larger icon size
      }}
    ></i>
    Talk to Us.
  </a>
</div>




          </div>
        </div>
      </div>
    </div>
  );
};
